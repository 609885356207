const sliders = () => {

    if ($('.main-slider').length > 0) {
        var sliderTimer = 5000;
        var $mainSlider = $('.main-slider');

        $mainSlider
            .slick({
                arrows: false,
                dots: true,
                autoplay: true,
                autoplaySpeed: sliderTimer,
                pauseOnFocus: false,
                pauseOnHover: false,
                fade: true
            })

    }

    if ($('.slider2').length > 0) {
        var sliderTimer = 5000;
        var $Slider2 = $('.slider2');

        $Slider2
            .slick({
                arrows: false,
                dots: true,
                autoplay: true,
                autoplaySpeed: sliderTimer,
                pauseOnFocus: false,
                pauseOnHover: false,
                fade: true
            })

    }

    if ($('.slider3').length > 0) {
        var sliderTimer = 5000;
        var $Slider3 = $('.slider3');

        $Slider3
            .slick({
                arrows: false,
                dots: true,
                autoplay: true,
                autoplaySpeed: sliderTimer,
                pauseOnFocus: false,
                pauseOnHover: false,
                fade: true
            })

    }

    if ($('.slider4').length > 0) {
        var $Slider4 = $('.slider4');

        $Slider4
            .slick({
                arrows: true,
                dots: false,
                prevArrow:"<button type='button' class='slick-prev slick-arrow'><i class='icon icon-angle-left'></i></button>",
                nextArrow:"<button type='button' class='slick-next slick-arrow'><i class='icon icon-angle-right'></i></button>"
            })

    }

}

module.exports = {
    sliders
};