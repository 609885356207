const filters = () => {

    if ($('.nav-filters').length > 0) {
        let filters = $('.nav-filters').find("input");
        let resultContainer = $(".filters-content");

        filters.each(function(index, item){
            $(item).change(function(){
                let selected = $(this).data("filter");

                if (selected == 'all') {
                    $(resultContainer).find('.filter-item').show();
                }else{
                    var elems = $(resultContainer).find('.filter-item[data-filter="'+selected+'"]');
                    $(resultContainer).find('.filter-item').not(elems).hide();
                    elems.show();
                }

                $("#filters-results").find(".product-item").removeClass("hidden");
                $("#more-filters-results").css("display", "none")

                $("#filters-results").find(".product-item:not([style*='display: none'])").each(function(index, item){

                    if (index > 5) {
                        $(this).addClass("hidden")
                    }
                })

                if ($("#filters-results").find(".product-item.hidden").length > 0) {
                    $("#more-filters-results").css("display", "flex")
                }

            })
        })
    }

    let selectedFilter;

    var tabs = $('button[data-bs-toggle="tab"]');
        tabs.each(function( index ) {
            $(this).on('shown.bs.tab', function (event) {
            event.target // newly activated tab
            event.relatedTarget // previous active tab

            let tabId = $(event.target).data('bs-target');

            console.log($(".nav-filters2").find("input[data-filter="+selectedFilter+"]"))

            $(".nav-filters2").find("input[data-filter="+selectedFilter+"]").change()

        })
    });

    if ($('.nav-filters2').length > 0) {
        let filters = $('.nav-filters2').find("input");
        let resultContainer = $(".filters-content");

        filters.each(function(index, item){
            $(item).change(function(){
                let selected = $(this).data("filter");

                selectedFilter = selected;

                if (selected == 'all') {
                    $(resultContainer).find('.filter-item').show();
                }else{
                    var elems = $(resultContainer).find('.filter-item[data-filter="'+selected+'"]');
                    $(resultContainer).find('.filter-item').not(elems).hide();
                    elems.show();
                }
            })
        })
    }

    if ($('.collapse-filters').length > 0) {
        let filters = $('.collapse-filters').find("input");
        let resultContainer = $(".filters-content");

        filters.each(function(index, item){

            $(item).change(function(){

                let chosen = $(item).data("filter")

                if (chosen != 'all') {
                    $('.collapse-filters').find("input[data-filter='all']").prop("checked", false);
                }

                if (chosen == 'all') {
                    $('.collapse-filters').find("input").prop("checked", false);
                    $('.collapse-filters').find("input[data-filter='all']").prop("checked", true);
                }

                let selected = $('.collapse-filters').find('input:checked');

                let selectedNames = [];

                selected.each(function(index, item){
                    selectedNames.push($(item).data("filter"))
                })

                console.log(selectedNames)

                if (chosen == 'all') {

                    $(resultContainer).find('.filter-item').show();

                }   else{

                    $(resultContainer).find('.filter-item').hide();

                    $.each(selectedNames, function(index, item){
                        $(resultContainer).find('.filter-item[data-filter="'+selectedNames[index]+'"]').show();
                    })
                }
            })
        })
    }

    if ($('#filters').length > 0) {

        $(document).ready(function() {
            var $products = $('.product-item'),
                $variants = $('.product-variants'),
                $filters = $("#filters input[type='radio']"),
                $filtersNested = $("#filters .filters-nested input[type='radio']"),
                product_filter = new ProductFilterLevel2($products, $variants, $filters, $filtersNested);
                product_filter.init();

                $("#filters #ikoni").click();
        });

        function ProductFilterLevel2(products, variants, filters, filtersNested) {
            var _this = this;

            this.init = function() {
                this.products = products;
                this.variants = variants;
                this.filters = filters;
                this.filtersNested = filtersNested;
                this.bindEvents();
            };

            this.bindEvents = function() {
                this.filters.click(this.filterGridProducts);
            };

            this.filterGridProducts = function() {

            //hide all
            _this.products.hide();
            var filteredProducts = _this.variants;

            //filter by collection, product
            var filterAttributes = $('.filter-attributes'); 

            // for each attribute check the corresponding attribute filters selected
            filterAttributes.each(function(){

                var selectedFilters = $(this).find('input:checked').not("[value='all']");

                // if selected filter by the attribute
                if (selectedFilters.length) {
                var selectedFiltersValues = [];
                selectedFilters.each(function() {
                    var currentFilter = $(this);
                    selectedFiltersValues.push("[data-" + currentFilter.attr('name') + "='" + currentFilter.val() + "']");
                });
                filteredProducts = filteredProducts.filter(selectedFiltersValues.join(','));
                }
            });
                filteredProducts.parent().show();
            };
        }
    }
}

module.exports = {
    filters
};