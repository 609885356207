//--general
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'bootstrap';
import { Tab, Collapse, Modal, Tooltip } from 'bootstrap';
import 'bootstrap-select';
import 'slick-carousel';
import { sliders } from './parts/sliders.js';
import { masonryGrid } from './parts/masonry.js';
import { navigation } from './parts/navigation.js';
require('readmore-js');
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';
import {filters} from './parts/filters';
import 'magnific-popup';
import AOS from 'aos'; 



(function ($) {

    var tabs = $('button[data-bs-toggle="tab"]');
        tabs.each(function( index ) {
            $(this).on('shown.bs.tab', function (event) {
            event.target // newly activated tab
            event.relatedTarget // previous active tab

            let tabId = $(event.target).data('bs-target');

            masonryGrid(tabId);
        })
    });

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
    })

    $('.popup').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		closeBtnInside: false,
		fixedContentPos: true,
		mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
		image: {
			verticalFit: true
		},
		zoom: {
			enabled: true,
			duration: 300 // don't foget to change the duration also in CSS
		}
    });
    
    $("#filters-results").find(".product-item:not([style*='display: none'])").each(function(index, item){
        if (index > 5) {
            $(this).addClass("hidden")
        }
    })

    $("#more-filters-results").click(function(){
        $("#filters-results").find(".product-item").removeClass("hidden");
        $(this).css("display", "none")
    })

    if ($(".detailsModal").length > 0) {
        $(".detailsModal").each(function(index){

            let idx = index + 1;
            let materialsNum = 'material' + idx;
            let modalElem;

            $(".material-list ."+ materialsNum).click(function(){


                modalElem = 'modal-' + $(this).data('modal');

                var modal = new Modal(document.getElementById(modalElem), {});

                modal.show()
                var item = $(this).data('id');

                $("#"+modalElem).find('.carousel-item[data-id='+item+']').addClass("active")

                var detailsModal = document.getElementById(modalElem)
                detailsModal.addEventListener('hidden.bs.modal', function (event) {
                    $("#"+modalElem).find('.carousel-item').removeClass("active")
                })
            })

            document.onkeydown = function(e) {
                switch(e.which) {
                    case 37: // left
                    $(".carousel-nav .carousel-control-prev-icon").click()
                    break;
            
                    case 39: // right
                    $(".carousel-nav .carousel-control-next-icon").click()
                    break;
            
                    default: return; // exit this handler for other keys
                }
                e.preventDefault(); // prevent the default action (scroll / move caret)
            };

        })
    }

    if ($("#favoritesModal").length > 0) {
        var modal = new Modal(document.getElementById('favoritesModal'), {})

        $("#favoritesModal-open").click(function(){
            modal.show()
        })
    }

    if ($("#simplebar1").length > 0) {
        const el = new SimpleBar(document.getElementById('simplebar1'));

        $("#simplebar1-up").click(function(){
            el.getScrollElement().scrollTop -=34
        })

        $("#simplebar1-down").click(function(){
            el.getScrollElement().scrollTop +=34
        })
        
    }

    $(window).scroll( function () {
        scrolled();
    });
    
    function scrolled () {
        if ( $(window).scrollTop() > 80 ) {
            $('#page-header').addClass("bg");
        } else {
            $('#page-header').removeClass("bg");
        }
    }

    const triggerTabList = [...document.querySelectorAll('#tabs button')];
    triggerTabList.forEach((triggerEl) => {
        const tabTrigger = new Tab(triggerEl)

        triggerEl.addEventListener('click', (e) => {
            e.preventDefault();
            if ($(window).width() > 1000) {
                tabTrigger.show();
            } else {
                tabTrigger.dispose();
            }
        })
    });

    //-- delay function for resizing
    var delay = (function() {
        var timer = 0;
        return function(callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        }
    })();

    if($(window).width() < 1000) {

        var collectionsHomeArr = $(".home-collections").find(".grid-item");

        collectionsHomeArr.each(function(index){
            if (index > 2) {
                $(this).addClass("hidden")
            }
        })

        var scollPos;

        $("#more-collections").click(function(){
            if (!$(this).hasClass("collapsed")) {
                collectionsHomeArr.each(function(index){
                    $(this).removeClass("hidden")
                })

                $(this).toggleClass("collapsed")

                $(this).find(".inner span").text("Pokaż mniej")

                scollPos = $(window).scrollTop()
            }
            else {
                collectionsHomeArr.each(function(index){

                    if (index > 2) {
                        $(this).addClass("hidden")
                    }
                })
                
                $(this).toggleClass("collapsed")

                $(this).find(".inner span").text("Pokaż więcej")

                $(window).scrollTop(scollPos)
            }
        })
    }

    $(".readmore").readmore({
        moreLink: '<button class="read-more-btn"><div class="inner">Pokaż więcej<img src="assets/img/arrow-black.svg" alt=""></div></button>',
        lessLink: '<button class="read-more-btn collapsed"><div class="inner">Pokaż mniej<img src="assets/img/arrow-black.svg" alt=""></div></button>'
    });

    $(document).ready(function(){

        filters();
        sliders();
        navigation();

        $("#search-btn").click(function(){
            $("#search-box").addClass("opened");
            $('body').addClass("menu-opened");
        })

        $("#search-close").click(function(){
            $("#search-box").removeClass("opened");
            $('body').removeClass("menu-opened");
        })

        if ($('#collapse-1').length > 0) {
            var collapse = document.getElementById('collapse-1')
            var bsCollapse = new Collapse(collapse, {
                toggle: false
            })

            $(".close-collapse-1").click(function(){
                bsCollapse.toggle()
            })

        }

        if ($('#collapse-2').length > 0) {
            var collapse2 = document.getElementById('collapse-2')
            var bsCollapse2 = new Collapse(collapse2, {
                toggle: false
            })

            $(".close-collapse-2").click(function(){
                bsCollapse2.toggle()
            })

        }

        //grid switcher

        $(".btn-list").click(function(){
            $('.product').removeClass("grid").addClass("list").parent().removeClass("col-12").addClass("col-6 col-sm-6 col-md-4")
            $('.grid-box').removeClass("grid").addClass("list").parent().removeClass("col-12").addClass("col-6 col-sm-6 col-md-4")
        })

        $(".btn-grid").click(function(){
            $('.product').removeClass("list").addClass("grid").parent().removeClass("col-6 col-sm-6 col-md-4 list").addClass('col-12')
            $('.grid-box').removeClass("list").addClass("grid").parent().removeClass("col-6 col-sm-6 col-md-4 list").addClass('col-12')
        })

        if ($(window).width() < 992) {
            $(".btn-grid").click();
        }

        $("#branches-filters-btn").click(function(){
            $("#branches-filters").addClass("opened")
        })

        $("#branches-filters__close, #branches-filters__apply").click(function(){
            $("#branches-filters").removeClass("opened")
        })

        $(".btn-scroll").on('click', function(event) {

            // Make sure this.hash has a value before overriding default behavior
            if (this.hash !== "") {
                // Prevent default anchor click behavior
                event.preventDefault();
        
                // Store hash
                var hash = this.hash;
        
                // Using jQuery's animate() method to add smooth page scroll
                // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                $('html, body').animate({
                    scrollTop: $(hash).offset().top
                }, 300, function(){
        
                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash;
                });
            } // End if
        });

    });


    $(window).on({
        load:function(){
            masonryGrid();
            $("#navigation").css("display", "flex");
            $("#search-box").css("display", "block");
            $("#branches-filters").css("display", "block");
            $(".preloader img").addClass("ready")
            $(".preloader").addClass("loaded")
        },
        resize:function(){


        },
        scroll:function(){

       }
    });

    
    AOS.init({
        offset: 150,
        duration: 800,
        delay: 120,
        startEvent: 'DOMContentLoaded',
        disable: 'mobile',
        once: true
    });

}(jQuery));