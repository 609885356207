const navigation = () => {

        var $navMobileSteps = $('#nav-mobile-steps'),
            $baseNavPage = $navMobileSteps.find("#page-one"),
            $navMobileStepsArr = $navMobileSteps.find(".page"),
            $navBtn = $("#nav-btn"),
            $nav = $("#navigation"),
            $navBtnClose = $("#nav-btn-close");

        $navMobileStepsArr.css("display","none")

        $baseNavPage.css("display","block")

        $navMobileSteps.css('width', 100 * $navMobileStepsArr.length + 'vw')

        $(".nav-step-btn").click(function(){
            var navPage = $(this).data("page");

            $navMobileStepsArr.css("display","none")

            $('#'+navPage).css("display","block")

            var offsetPage = $('#'+navPage).offset().left;

            $navMobileSteps.css("transform", "translateX("+-offsetPage+'px'+")")
        })

        $(".back-btn").click(function(){
            $navMobileSteps.css("transform", "translateX(0px)");
            $baseNavPage.css("display","block");
        })

        $navBtn.click(function(){
            $nav.addClass("opened")
            $('body').addClass("menu-opened")
        })

        $navBtnClose.click(function(){
            $nav.removeClass("opened")
            $('body').removeClass("menu-opened")
        })
}

module.exports = {
    navigation
};