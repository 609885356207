const Masonry = require('masonry-layout');

const masonryGrid = (tabId) => {

    if ($('.masonry-grid').length > 0) {

        let tabID;

        if (tabId == undefined) {
            tabID = '';
        } else {
            tabID = tabId
        }

        var masonryOptions = {
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true
        };


        var $grid;

        function setGrid() {
            if ($(window).width() <= 991 && $grid) {
                $grid.destroy(); // destroy
                $grid = null;
            } else if ($(window).width() > 991 && !$grid) {
                
                $grid = new Masonry(tabID+ ' .masonry-grid', masonryOptions);
            }
        }

        setGrid()

        $(window).resize(function(){
            setGrid()
        })
    }

}

module.exports = {
    masonryGrid
};